import validate from "/html/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/html/middleware/auth.global.ts";
import manifest_45route_45rule from "/html/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "organization-manager-level-auth": () => import("/html/middleware/organization-manager-level-auth.ts"),
  "system-manager-level-auth": () => import("/html/middleware/system-manager-level-auth.ts")
}
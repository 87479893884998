import { cookieName } from '~/store/auth';
import { useSnackbarStore } from '~/store/snackbar';

export type ApiResult<T> = { result: T; error: any };

type ApiError = {
  statusCode?: number;
  message: string;
  data?: any;
};

/**
 * DEPERECATED: use useMyFetchTyped instead
 */
export const useMyFetch = (request: string, opts?: any) => {
  const config = useRuntimeConfig();
  const token = useCookie(cookieName);
  const headers: any = {
    Authorization: `Bearer ${token.value}`,
  };

  return useFetch(request, {
    baseURL: config.public.baseURL,
    ...opts,
    headers: {
      ...opts?.headers,
      ...headers,
      accept: 'application/json',
    },
    watch: false,
  });
};

export async function useMyFetchTyped<T>(request: string, opts?: any): Promise<ApiResult<T>> {
  const response = await useMyFetch(request, {
    method: 'GET',
    ...opts,
  });

  if (response.error.value && !opts?.skipDefaultErrorHandling) {
    const { showMessage } = useSnackbarStore();
    showMessage({ content: '読み込みが失敗しました', color: 'error' });
  }

  return { result: response.data.value as T, error: response.error.value as ApiError };
}

import { createVuetify } from 'vuetify';
import { en, ja } from 'vuetify/locale';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import * as labsComponents from 'vuetify/labs/components';

const lightTheme = {
  dark: false,
  colors: {
    background: '#FFFFFF',
    surface: '#FFFFFF',
    'surface-bright': '#FFFFFF',
    'surface-light': '#EEEEEE',
    'surface-variant': '#424242',
    'on-surface-variant': '#EEEEEE',
    primary: '#FFB400',
    'primary-darken-1': '#FFB400',
    secondary: '#0F3460',
    'secondary-darken-1': '#0F3460',
    error: '#DD2C00',
    info: '#0096FF',
    success: '#4CAF50',
    warning: '#FB8C00',
  },
  variables: {
    'border-color': '#000000',
    'border-opacity': 0.12,
    'high-emphasis-opacity': 0.87,
    'medium-emphasis-opacity': 0.6,
    'disabled-opacity': 0.38,
    'idle-opacity': 0.04,
    'hover-opacity': 0.04,
    'focus-opacity': 0.12,
    'selected-opacity': 0.08,
    'activated-opacity': 0.12,
    'pressed-opacity': 0.12,
    'dragged-opacity': 0.08,
    'theme-kbd': '#212529',
    'theme-on-kbd': '#FFFFFF',
    'theme-code': '#F5F5F5',
    'theme-on-code': '#000000',
  },
};

export default defineNuxtPlugin(nuxtApp => {
  const vuetify = createVuetify({
    ssr: true,
    display: {
      mobileBreakpoint: 'sm',
    },
    components: {
      ...components,
      ...labsComponents,
    },
    directives,
    theme: {
      themes: {
        light: lightTheme,
      },
      defaultTheme: 'light',
    },
    locale: {
      locale: 'ja',
      fallback: 'en',
      messages: { en, ja },
    },
    defaults: {
      VSelect: {
        density: 'compact',
      },
      VTextField: {
        density: 'compact',
      },
      VTextarea: {
        density: 'compact',
      },
      VAutocomplete: {
        density: 'compact',
      },
      VCombobox: {
        density: 'compact',
      },
    },
  });

  nuxtApp.vueApp.use(vuetify);
});

import { defineStore } from 'pinia';
import { useSnackbarStore } from './snackbar';

interface OperatorPayloadInterface {
  email: string;
  password: string;
}

interface AuthStateInterface {
  authenticated: boolean;
  loading: boolean;
  operator: Operator | null;
  organizations: any[];
  shops: Shop[];
  currentShop: Shop | null;
  enums: SystemEnum;
}

export const cookieName = 'admin_token'

export const useAuthStore = defineStore('auth', {
  state: () => ({
    authenticated: false,
    loading: false,
    operator: null,
    organizations: [],
    shops: [],
    currentShop: null,
    enums: {} as SystemEnum,
  } as AuthStateInterface),
  actions: {
    async login({ email, password }: OperatorPayloadInterface): Promise<boolean> {
      const { data, error, pending }: any = await useMyFetch('/api/v1/admin/auth/login', {
        method: 'post',
        body: {
          email,
          password,
        },
      });
      this.loading = pending

      const { showMessage } = useSnackbarStore()
      if (error.value) {
        showMessage({ content: 'ログインに失敗しました', color: 'error' })
        return false
      }
      const { token, expires_in } = data?.value
      if (!token || expires_in <= 0) {
        // Impossible case except backend bug
        showMessage({ content: 'ログインに失敗しました', color: 'error' })
        return false
      }

      const tokenCookie = useCookie(cookieName, {
        maxAge: expires_in,
        sameSite: 'strict',
        secure: true,
      })
      tokenCookie.value = token.bearer
      this.authenticated = true

      return true
    },

    async refreshProfile() {
      if (this.operator) {
        // Already loaded profile
        return
      }

      const { data, error, pending }: any = await useMyFetch('/api/v1/admin/profile/info', {
        method: 'get',
      });
      this.loading = pending

      if (error.value) {
        const { statusCode } = error.value
        const { showMessage } = useSnackbarStore()
        if (statusCode === 403 || statusCode === 401) {
          // Clear token
          const token = useCookie(cookieName)
          token.value = null
          this.authenticated = false
          showMessage({ content: 'ログインしてください', color: 'error' })
          return
        }
        showMessage({ content: 'エラーが発生しました', color: 'error' })
        return
      }

      const { operator, organizations, shops, enums } = data?.value
      this.operator = operator
      this.organizations = organizations
      this.shops = shops
      this.enums = enums

      for (const shop of this.shops) {
        if (shop.id === this.operator?.current_shop_id) {
          this.currentShop = shop
        }
      }
    },

    async logout() {
      await useMyFetch('/api/v1/admin/auth/logout', {
        method: 'post',
      });
      // Clear token
      const token = useCookie(cookieName)
      token.value = null
      this.authenticated = false
      this.operator = null
      navigateTo('/signin')
    },
  },
})

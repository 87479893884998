import { defineStore } from 'pinia';
export const useSnackbarStore = defineStore('snackbar', {
  state: () => ({
    content: '',
    color: '',
    active: false,
  }),

  getters: {
    getContent(): string {
      return this.content;
    },
    getColor(): string {
      return this.color;
    },
  },

  actions: {
    showMessage(payload: { content: string; color: string }) {
      this.content = payload.content;
      this.color = payload.color;
      this.active = true;
    },
  },
})

import { useAuthStore, cookieName } from "~/store/auth";

export default defineNuxtRouteMiddleware(async (to) => {
  // Handle admin authentication
  const { authenticated } = storeToRefs(useAuthStore())
  const token = useCookie(cookieName)

  const isAuthenticated = token.value
  if (isAuthenticated) {
    authenticated.value = true
    await useAuthStore().refreshProfile()
  }

  // Redirect to admin home page if authenticated
  if (isAuthenticated && to?.name === 'signin') {
    return navigateTo('/dashboard')
  }

  // Redirect to login page if not authenticated
  if (!isAuthenticated && to?.name !== 'signin') {
    // abortNavigation()
    return navigateTo('/signin')
  }

  return
})

import { default as indexI9nCepyvOxMeta } from "/html/pages/campaign-reports/index.vue?macro=true";
import { default as _91id_93347TRcNxNDMeta } from "/html/pages/campaigns/[id].vue?macro=true";
import { default as indexe8i59w8nEuMeta } from "/html/pages/campaigns/index.vue?macro=true";
import { default as newC9TBwvyvS8Meta } from "/html/pages/campaigns/new.vue?macro=true";
import { default as _91id_93B4pQKCBST1Meta } from "/html/pages/coupons/[id].vue?macro=true";
import { default as indexyvlze5Gd1pMeta } from "/html/pages/coupons/index.vue?macro=true";
import { default as newTJ8wyWrUQ7Meta } from "/html/pages/coupons/new.vue?macro=true";
import { default as indexFfB84yCksOMeta } from "/html/pages/dashboard/index.vue?macro=true";
import { default as _91id_931twfuEjajHMeta } from "/html/pages/genres/[id].vue?macro=true";
import { default as indexB8qYu2BjjQMeta } from "/html/pages/genres/index.vue?macro=true";
import { default as newTxqaSBVxjyMeta } from "/html/pages/genres/new.vue?macro=true";
import { default as indexIh7resPdAkMeta } from "/html/pages/index.vue?macro=true";
import { default as _91id_93ho7yUeI7eaMeta } from "/html/pages/menu-items/[id].vue?macro=true";
import { default as index3zM3qcRgWbMeta } from "/html/pages/menu-items/index.vue?macro=true";
import { default as newm73ijZEAJlMeta } from "/html/pages/menu-items/new.vue?macro=true";
import { default as _91id_93ZzuNMeRHUsMeta } from "/html/pages/news/[id].vue?macro=true";
import { default as indexDqSBhlpUz9Meta } from "/html/pages/news/index.vue?macro=true";
import { default as newNeQoDEIgjLMeta } from "/html/pages/news/new.vue?macro=true";
import { default as _91id_936ZZpzQhxfxMeta } from "/html/pages/operators/[id].vue?macro=true";
import { default as indexMcs0QV33daMeta } from "/html/pages/operators/index.vue?macro=true";
import { default as new5EMiRjFrAHMeta } from "/html/pages/operators/new.vue?macro=true";
import { default as _91id_93VP7gQNSKbMMeta } from "/html/pages/organizations/[id].vue?macro=true";
import { default as indexU7N9V9MX6nMeta } from "/html/pages/organizations/index.vue?macro=true";
import { default as indexFptdu5oDqOMeta } from "/html/pages/report-points/index.vue?macro=true";
import { default as indexxypiisMAZ7Meta } from "/html/pages/report-receipts/index.vue?macro=true";
import { default as indextIGue7Uuv1Meta } from "/html/pages/report-reservations/index.vue?macro=true";
import { default as index2PWO7oaeAJMeta } from "/html/pages/report-rfm/index.vue?macro=true";
import { default as _91id_936Cj6kMSA3OMeta } from "/html/pages/reservations/[id].vue?macro=true";
import { default as indexqVlNE7E0nGMeta } from "/html/pages/reservations/index.vue?macro=true";
import { default as _91id_93qzMqLCrZiTMeta } from "/html/pages/shops/[id].vue?macro=true";
import { default as indexHQJnko0xoXMeta } from "/html/pages/shops/index.vue?macro=true";
import { default as newOh6HuTgvhzMeta } from "/html/pages/shops/new.vue?macro=true";
import { default as signinZLX4IYXrMZMeta } from "/html/pages/signin.vue?macro=true";
import { default as page_45views4SIm3te3jzMeta } from "/html/pages/statistics/page-views.vue?macro=true";
import { default as reservation_45viewsfttdUtrVZ0Meta } from "/html/pages/statistics/reservation-views.vue?macro=true";
import { default as _91id_931KOy5qdgFJMeta } from "/html/pages/user-segments/[id].vue?macro=true";
import { default as indexMhvLUOhSIZMeta } from "/html/pages/user-segments/index.vue?macro=true";
import { default as newJacvNIYou5Meta } from "/html/pages/user-segments/new.vue?macro=true";
import { default as _91id_93Jj3ctdVTBOMeta } from "/html/pages/users/[id].vue?macro=true";
import { default as indexKU1wLHtDKfMeta } from "/html/pages/users/index.vue?macro=true";
export default [
  {
    name: indexI9nCepyvOxMeta?.name ?? "campaign-reports",
    path: indexI9nCepyvOxMeta?.path ?? "/campaign-reports",
    meta: indexI9nCepyvOxMeta || {},
    alias: indexI9nCepyvOxMeta?.alias || [],
    redirect: indexI9nCepyvOxMeta?.redirect || undefined,
    component: () => import("/html/pages/campaign-reports/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93347TRcNxNDMeta?.name ?? "campaigns-id",
    path: _91id_93347TRcNxNDMeta?.path ?? "/campaigns/:id()",
    meta: _91id_93347TRcNxNDMeta || {},
    alias: _91id_93347TRcNxNDMeta?.alias || [],
    redirect: _91id_93347TRcNxNDMeta?.redirect || undefined,
    component: () => import("/html/pages/campaigns/[id].vue").then(m => m.default || m)
  },
  {
    name: indexe8i59w8nEuMeta?.name ?? "campaigns",
    path: indexe8i59w8nEuMeta?.path ?? "/campaigns",
    meta: indexe8i59w8nEuMeta || {},
    alias: indexe8i59w8nEuMeta?.alias || [],
    redirect: indexe8i59w8nEuMeta?.redirect || undefined,
    component: () => import("/html/pages/campaigns/index.vue").then(m => m.default || m)
  },
  {
    name: newC9TBwvyvS8Meta?.name ?? "campaigns-new",
    path: newC9TBwvyvS8Meta?.path ?? "/campaigns/new",
    meta: newC9TBwvyvS8Meta || {},
    alias: newC9TBwvyvS8Meta?.alias || [],
    redirect: newC9TBwvyvS8Meta?.redirect || undefined,
    component: () => import("/html/pages/campaigns/new.vue").then(m => m.default || m)
  },
  {
    name: _91id_93B4pQKCBST1Meta?.name ?? "coupons-id",
    path: _91id_93B4pQKCBST1Meta?.path ?? "/coupons/:id()",
    meta: _91id_93B4pQKCBST1Meta || {},
    alias: _91id_93B4pQKCBST1Meta?.alias || [],
    redirect: _91id_93B4pQKCBST1Meta?.redirect || undefined,
    component: () => import("/html/pages/coupons/[id].vue").then(m => m.default || m)
  },
  {
    name: indexyvlze5Gd1pMeta?.name ?? "coupons",
    path: indexyvlze5Gd1pMeta?.path ?? "/coupons",
    meta: indexyvlze5Gd1pMeta || {},
    alias: indexyvlze5Gd1pMeta?.alias || [],
    redirect: indexyvlze5Gd1pMeta?.redirect || undefined,
    component: () => import("/html/pages/coupons/index.vue").then(m => m.default || m)
  },
  {
    name: newTJ8wyWrUQ7Meta?.name ?? "coupons-new",
    path: newTJ8wyWrUQ7Meta?.path ?? "/coupons/new",
    meta: newTJ8wyWrUQ7Meta || {},
    alias: newTJ8wyWrUQ7Meta?.alias || [],
    redirect: newTJ8wyWrUQ7Meta?.redirect || undefined,
    component: () => import("/html/pages/coupons/new.vue").then(m => m.default || m)
  },
  {
    name: indexFfB84yCksOMeta?.name ?? "dashboard",
    path: indexFfB84yCksOMeta?.path ?? "/dashboard",
    meta: indexFfB84yCksOMeta || {},
    alias: indexFfB84yCksOMeta?.alias || [],
    redirect: indexFfB84yCksOMeta?.redirect || undefined,
    component: () => import("/html/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_931twfuEjajHMeta?.name ?? "genres-id",
    path: _91id_931twfuEjajHMeta?.path ?? "/genres/:id()",
    meta: _91id_931twfuEjajHMeta || {},
    alias: _91id_931twfuEjajHMeta?.alias || [],
    redirect: _91id_931twfuEjajHMeta?.redirect || undefined,
    component: () => import("/html/pages/genres/[id].vue").then(m => m.default || m)
  },
  {
    name: indexB8qYu2BjjQMeta?.name ?? "genres",
    path: indexB8qYu2BjjQMeta?.path ?? "/genres",
    meta: indexB8qYu2BjjQMeta || {},
    alias: indexB8qYu2BjjQMeta?.alias || [],
    redirect: indexB8qYu2BjjQMeta?.redirect || undefined,
    component: () => import("/html/pages/genres/index.vue").then(m => m.default || m)
  },
  {
    name: newTxqaSBVxjyMeta?.name ?? "genres-new",
    path: newTxqaSBVxjyMeta?.path ?? "/genres/new",
    meta: newTxqaSBVxjyMeta || {},
    alias: newTxqaSBVxjyMeta?.alias || [],
    redirect: newTxqaSBVxjyMeta?.redirect || undefined,
    component: () => import("/html/pages/genres/new.vue").then(m => m.default || m)
  },
  {
    name: indexIh7resPdAkMeta?.name ?? "index",
    path: indexIh7resPdAkMeta?.path ?? "/",
    meta: indexIh7resPdAkMeta || {},
    alias: indexIh7resPdAkMeta?.alias || [],
    redirect: indexIh7resPdAkMeta?.redirect || undefined,
    component: () => import("/html/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93ho7yUeI7eaMeta?.name ?? "menu-items-id",
    path: _91id_93ho7yUeI7eaMeta?.path ?? "/menu-items/:id()",
    meta: _91id_93ho7yUeI7eaMeta || {},
    alias: _91id_93ho7yUeI7eaMeta?.alias || [],
    redirect: _91id_93ho7yUeI7eaMeta?.redirect || undefined,
    component: () => import("/html/pages/menu-items/[id].vue").then(m => m.default || m)
  },
  {
    name: index3zM3qcRgWbMeta?.name ?? "menu-items",
    path: index3zM3qcRgWbMeta?.path ?? "/menu-items",
    meta: index3zM3qcRgWbMeta || {},
    alias: index3zM3qcRgWbMeta?.alias || [],
    redirect: index3zM3qcRgWbMeta?.redirect || undefined,
    component: () => import("/html/pages/menu-items/index.vue").then(m => m.default || m)
  },
  {
    name: newm73ijZEAJlMeta?.name ?? "menu-items-new",
    path: newm73ijZEAJlMeta?.path ?? "/menu-items/new",
    meta: newm73ijZEAJlMeta || {},
    alias: newm73ijZEAJlMeta?.alias || [],
    redirect: newm73ijZEAJlMeta?.redirect || undefined,
    component: () => import("/html/pages/menu-items/new.vue").then(m => m.default || m)
  },
  {
    name: _91id_93ZzuNMeRHUsMeta?.name ?? "news-id",
    path: _91id_93ZzuNMeRHUsMeta?.path ?? "/news/:id()",
    meta: _91id_93ZzuNMeRHUsMeta || {},
    alias: _91id_93ZzuNMeRHUsMeta?.alias || [],
    redirect: _91id_93ZzuNMeRHUsMeta?.redirect || undefined,
    component: () => import("/html/pages/news/[id].vue").then(m => m.default || m)
  },
  {
    name: indexDqSBhlpUz9Meta?.name ?? "news",
    path: indexDqSBhlpUz9Meta?.path ?? "/news",
    meta: indexDqSBhlpUz9Meta || {},
    alias: indexDqSBhlpUz9Meta?.alias || [],
    redirect: indexDqSBhlpUz9Meta?.redirect || undefined,
    component: () => import("/html/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: newNeQoDEIgjLMeta?.name ?? "news-new",
    path: newNeQoDEIgjLMeta?.path ?? "/news/new",
    meta: newNeQoDEIgjLMeta || {},
    alias: newNeQoDEIgjLMeta?.alias || [],
    redirect: newNeQoDEIgjLMeta?.redirect || undefined,
    component: () => import("/html/pages/news/new.vue").then(m => m.default || m)
  },
  {
    name: _91id_936ZZpzQhxfxMeta?.name ?? "operators-id",
    path: _91id_936ZZpzQhxfxMeta?.path ?? "/operators/:id()",
    meta: _91id_936ZZpzQhxfxMeta || {},
    alias: _91id_936ZZpzQhxfxMeta?.alias || [],
    redirect: _91id_936ZZpzQhxfxMeta?.redirect || undefined,
    component: () => import("/html/pages/operators/[id].vue").then(m => m.default || m)
  },
  {
    name: indexMcs0QV33daMeta?.name ?? "operators",
    path: indexMcs0QV33daMeta?.path ?? "/operators",
    meta: indexMcs0QV33daMeta || {},
    alias: indexMcs0QV33daMeta?.alias || [],
    redirect: indexMcs0QV33daMeta?.redirect || undefined,
    component: () => import("/html/pages/operators/index.vue").then(m => m.default || m)
  },
  {
    name: new5EMiRjFrAHMeta?.name ?? "operators-new",
    path: new5EMiRjFrAHMeta?.path ?? "/operators/new",
    meta: new5EMiRjFrAHMeta || {},
    alias: new5EMiRjFrAHMeta?.alias || [],
    redirect: new5EMiRjFrAHMeta?.redirect || undefined,
    component: () => import("/html/pages/operators/new.vue").then(m => m.default || m)
  },
  {
    name: _91id_93VP7gQNSKbMMeta?.name ?? "organizations-id",
    path: _91id_93VP7gQNSKbMMeta?.path ?? "/organizations/:id()",
    meta: _91id_93VP7gQNSKbMMeta || {},
    alias: _91id_93VP7gQNSKbMMeta?.alias || [],
    redirect: _91id_93VP7gQNSKbMMeta?.redirect || undefined,
    component: () => import("/html/pages/organizations/[id].vue").then(m => m.default || m)
  },
  {
    name: indexU7N9V9MX6nMeta?.name ?? "organizations",
    path: indexU7N9V9MX6nMeta?.path ?? "/organizations",
    meta: indexU7N9V9MX6nMeta || {},
    alias: indexU7N9V9MX6nMeta?.alias || [],
    redirect: indexU7N9V9MX6nMeta?.redirect || undefined,
    component: () => import("/html/pages/organizations/index.vue").then(m => m.default || m)
  },
  {
    name: indexFptdu5oDqOMeta?.name ?? "report-points",
    path: indexFptdu5oDqOMeta?.path ?? "/report-points",
    meta: indexFptdu5oDqOMeta || {},
    alias: indexFptdu5oDqOMeta?.alias || [],
    redirect: indexFptdu5oDqOMeta?.redirect || undefined,
    component: () => import("/html/pages/report-points/index.vue").then(m => m.default || m)
  },
  {
    name: indexxypiisMAZ7Meta?.name ?? "report-receipts",
    path: indexxypiisMAZ7Meta?.path ?? "/report-receipts",
    meta: indexxypiisMAZ7Meta || {},
    alias: indexxypiisMAZ7Meta?.alias || [],
    redirect: indexxypiisMAZ7Meta?.redirect || undefined,
    component: () => import("/html/pages/report-receipts/index.vue").then(m => m.default || m)
  },
  {
    name: indextIGue7Uuv1Meta?.name ?? "report-reservations",
    path: indextIGue7Uuv1Meta?.path ?? "/report-reservations",
    meta: indextIGue7Uuv1Meta || {},
    alias: indextIGue7Uuv1Meta?.alias || [],
    redirect: indextIGue7Uuv1Meta?.redirect || undefined,
    component: () => import("/html/pages/report-reservations/index.vue").then(m => m.default || m)
  },
  {
    name: index2PWO7oaeAJMeta?.name ?? "report-rfm",
    path: index2PWO7oaeAJMeta?.path ?? "/report-rfm",
    meta: index2PWO7oaeAJMeta || {},
    alias: index2PWO7oaeAJMeta?.alias || [],
    redirect: index2PWO7oaeAJMeta?.redirect || undefined,
    component: () => import("/html/pages/report-rfm/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_936Cj6kMSA3OMeta?.name ?? "reservations-id",
    path: _91id_936Cj6kMSA3OMeta?.path ?? "/reservations/:id()",
    meta: _91id_936Cj6kMSA3OMeta || {},
    alias: _91id_936Cj6kMSA3OMeta?.alias || [],
    redirect: _91id_936Cj6kMSA3OMeta?.redirect || undefined,
    component: () => import("/html/pages/reservations/[id].vue").then(m => m.default || m)
  },
  {
    name: indexqVlNE7E0nGMeta?.name ?? "reservations",
    path: indexqVlNE7E0nGMeta?.path ?? "/reservations",
    meta: indexqVlNE7E0nGMeta || {},
    alias: indexqVlNE7E0nGMeta?.alias || [],
    redirect: indexqVlNE7E0nGMeta?.redirect || undefined,
    component: () => import("/html/pages/reservations/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93qzMqLCrZiTMeta?.name ?? "shops-id",
    path: _91id_93qzMqLCrZiTMeta?.path ?? "/shops/:id()",
    meta: _91id_93qzMqLCrZiTMeta || {},
    alias: _91id_93qzMqLCrZiTMeta?.alias || [],
    redirect: _91id_93qzMqLCrZiTMeta?.redirect || undefined,
    component: () => import("/html/pages/shops/[id].vue").then(m => m.default || m)
  },
  {
    name: indexHQJnko0xoXMeta?.name ?? "shops",
    path: indexHQJnko0xoXMeta?.path ?? "/shops",
    meta: indexHQJnko0xoXMeta || {},
    alias: indexHQJnko0xoXMeta?.alias || [],
    redirect: indexHQJnko0xoXMeta?.redirect || undefined,
    component: () => import("/html/pages/shops/index.vue").then(m => m.default || m)
  },
  {
    name: newOh6HuTgvhzMeta?.name ?? "shops-new",
    path: newOh6HuTgvhzMeta?.path ?? "/shops/new",
    meta: newOh6HuTgvhzMeta || {},
    alias: newOh6HuTgvhzMeta?.alias || [],
    redirect: newOh6HuTgvhzMeta?.redirect || undefined,
    component: () => import("/html/pages/shops/new.vue").then(m => m.default || m)
  },
  {
    name: signinZLX4IYXrMZMeta?.name ?? "signin",
    path: signinZLX4IYXrMZMeta?.path ?? "/signin",
    meta: signinZLX4IYXrMZMeta || {},
    alias: signinZLX4IYXrMZMeta?.alias || [],
    redirect: signinZLX4IYXrMZMeta?.redirect || undefined,
    component: () => import("/html/pages/signin.vue").then(m => m.default || m)
  },
  {
    name: page_45views4SIm3te3jzMeta?.name ?? "statistics-page-views",
    path: page_45views4SIm3te3jzMeta?.path ?? "/statistics/page-views",
    meta: page_45views4SIm3te3jzMeta || {},
    alias: page_45views4SIm3te3jzMeta?.alias || [],
    redirect: page_45views4SIm3te3jzMeta?.redirect || undefined,
    component: () => import("/html/pages/statistics/page-views.vue").then(m => m.default || m)
  },
  {
    name: reservation_45viewsfttdUtrVZ0Meta?.name ?? "statistics-reservation-views",
    path: reservation_45viewsfttdUtrVZ0Meta?.path ?? "/statistics/reservation-views",
    meta: reservation_45viewsfttdUtrVZ0Meta || {},
    alias: reservation_45viewsfttdUtrVZ0Meta?.alias || [],
    redirect: reservation_45viewsfttdUtrVZ0Meta?.redirect || undefined,
    component: () => import("/html/pages/statistics/reservation-views.vue").then(m => m.default || m)
  },
  {
    name: _91id_931KOy5qdgFJMeta?.name ?? "user-segments-id",
    path: _91id_931KOy5qdgFJMeta?.path ?? "/user-segments/:id()",
    meta: _91id_931KOy5qdgFJMeta || {},
    alias: _91id_931KOy5qdgFJMeta?.alias || [],
    redirect: _91id_931KOy5qdgFJMeta?.redirect || undefined,
    component: () => import("/html/pages/user-segments/[id].vue").then(m => m.default || m)
  },
  {
    name: indexMhvLUOhSIZMeta?.name ?? "user-segments",
    path: indexMhvLUOhSIZMeta?.path ?? "/user-segments",
    meta: indexMhvLUOhSIZMeta || {},
    alias: indexMhvLUOhSIZMeta?.alias || [],
    redirect: indexMhvLUOhSIZMeta?.redirect || undefined,
    component: () => import("/html/pages/user-segments/index.vue").then(m => m.default || m)
  },
  {
    name: newJacvNIYou5Meta?.name ?? "user-segments-new",
    path: newJacvNIYou5Meta?.path ?? "/user-segments/new",
    meta: newJacvNIYou5Meta || {},
    alias: newJacvNIYou5Meta?.alias || [],
    redirect: newJacvNIYou5Meta?.redirect || undefined,
    component: () => import("/html/pages/user-segments/new.vue").then(m => m.default || m)
  },
  {
    name: _91id_93Jj3ctdVTBOMeta?.name ?? "users-id",
    path: _91id_93Jj3ctdVTBOMeta?.path ?? "/users/:id()",
    meta: _91id_93Jj3ctdVTBOMeta || {},
    alias: _91id_93Jj3ctdVTBOMeta?.alias || [],
    redirect: _91id_93Jj3ctdVTBOMeta?.redirect || undefined,
    component: () => import("/html/pages/users/[id].vue").then(m => m.default || m)
  },
  {
    name: indexKU1wLHtDKfMeta?.name ?? "users",
    path: indexKU1wLHtDKfMeta?.path ?? "/users",
    meta: indexKU1wLHtDKfMeta || {},
    alias: indexKU1wLHtDKfMeta?.alias || [],
    redirect: indexKU1wLHtDKfMeta?.redirect || undefined,
    component: () => import("/html/pages/users/index.vue").then(m => m.default || m)
  }
]